// Paper & Background Color
$black:#000000;
$paper: #ffffff;
$background: #ffffff;

// Primary Colors
$blue25: #E5F6FE;
$blue50: #e3f2fd;
$blue200: #90caf9;
$blue500: #2196f3;
$blue600: #1e88e5;
$blue800: #1565c0;

// Success Colors
$A100: #b9f6ca;
$A200: #69f0ae;
$A700: #00c853;

// Purple Colors
$deepPurple50: #ede7f6;
$deepPurple200: #b39ddb;
$deepPurple500: #673ab7;
$deepPurple600: #5e35b1;
$deepPurple800: #4527a0;
$deepPurple900: #812990; //nexen color
$purPlesearch: linear-gradient(to right, #812990, 50%, #280050); //searchbox

// Error Colors
$red200: #ef9a9a;
$red500: #f44336;
$red800: #c62828;

// Orange Colors
$deepOrange50: #fbe9e7;
$deepOrange200: #ffab91;
$deepOrange500: #ff9243;
$deepOrange800: #d84315;

// Warning Colors
$amber50: #fff8e1;
$amber100: #ffe57f;
$amber500: #ffc107;

// Grey Colors
$grey50: #fafafa;
$grey100: #f5f5f5;
$grey200: #eeeeee;
$grey300: #e0e0e0;
$grey400: #cecece;
$grey500: #9e9e9e;
$grey600: #757575;
$grey700: #616161;
$grey800: #313131;
$grey900: #212121;

$paperDark: #111936; // level 4

$textPrimary: #8492c4;
$textSecondary: #bdc8f0;

// this will use in javascript
:export {
    black:$black;
    paper: $paper;

    paperDark: $paperDark;
    textPrimary: $textPrimary;
    textSecondary: $textSecondary;

    blue25: $blue25;
    blue50: $blue50;
    blue200: $blue200;
    blue500: $blue500;
    blue600: $blue600;
    blue800: $blue800;

    A100: $A100;
    A200: $A200;
    A700: $A700;

    deepPurple50: $deepPurple50;
    deepPurple200: $deepPurple200;
    deepPurple500: $deepPurple500;
    deepPurple600: $deepPurple600;
    deepPurple800: $deepPurple800;
    deepPurple900: $deepPurple900;
    purPlesearch: $purPlesearch;

    amber50: $amber50;
    amber100: $amber100;
    amber500: $amber500;

    grey50: $grey50;
    grey100: $grey100;
    grey200: $grey200;
    grey300: $grey300;
    grey500: $grey500;
    grey600: $grey600;
    grey700: $grey700;
    grey800: $grey800;
    grey900: $grey900;

    red200: $red200;
    red500: $red500;
    red800: $red800;

    deepOrange50: $deepOrange50;
    deepOrange200: $deepOrange200;
    deepOrange500: $deepOrange500;
    deepOrange800: $deepOrange800;
}

//preview popup
.preview-show{
    display: block;
    position:fixed;
    top:0;
    left: 0;
    z-index: 1200;
    width: 100%;
    height: 100%;
    background: rgb(0,0,0,0.5);
    .sysmailtemplatepopup{
        width: calc(100% - 50px);
        max-width: 1800px;
        margin: 7% auto;
        .content{
            height: 300px;
            max-height: calc(100vh - 15rem);
            opacity: 1;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
}
.preview-hide{
    display: none;
    z-index: 5;
    position: absolute;
}

//sysMailTemplate
.save-show{
    display: block;
    position:fixed;
    top:0;
    left: 0;
    z-index: 1200;
    width: 100%;
    height: 100%;
    background: rgb(0,0,0,0.5);
    .savepop{
        width: calc(100% - 50px);
        max-width: 1800px;
        margin: 7% auto;
        .content{
            max-height: calc(100vh - 15rem);
            opacity: 1;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
    
}
.save-hide{
    display: none;
    z-index: 5;
    position: absolute;
}

//temp popup
.temp-show{
    display: block;
    position:fixed;
    top:0;
    left: 0;
    z-index: 1200;
    width: 100%;
    height: 100%;
    background: rgb(0,0,0,0.5);
    
    .temppop{
        width: calc(100% - 50px);
        max-width: 1800px;
        margin: 10% auto;
        .content{
            max-height: calc(100vh - 15rem);
            opacity: 1;
            overflow-x: hidden;
            overflow-y: auto;
        }
}
    
}
.temp-hide{
    display: none;
    z-index: 5;
    position: absolute;
}

//customer popup
.customerinfopopup-show{
    display: block;
    position:fixed;
    top:0;
    left: 0;
    z-index: 1200;
    width: 100%;
    height: 100%;
    background: rgb(0,0,0,0.5);
   
    .customerinfo{
         width: calc(100% - 50px);
        max-width: 600px;
        margin: 10% auto;
        .content{
            max-height: calc(100vh - 15rem);
            opacity: 1;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
    
}
.customerinfopopup-hide{
    display: none;
    z-index: 5;
    position: absolute;
}

//productinfo popup
.producinfopopup-show{
    display: block;
    position:fixed;
    top:0;
    left: 0;
    z-index: 1200;
    width: 100%;
    height: 100%;
    background: rgb(0,0,0,0.5);
    .producinfo{
         width: calc(100% - 50px);
        max-width: 600px;
        margin: 10% auto;
        .content{
            max-height: calc(100vh - 15rem);
            opacity: 1;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
    
}
.producinfopopup-hide{
    display: none;
    z-index: 5;
    position: absolute;
}

//mst customer popup
.mstcustomer-show{
    display: block;
    position:fixed;
    top:0;
    left: 0;
    z-index: 1200;
    width: 100%;
    height: 100%;
    background: rgb(0,0,0,0.5);
    .mstcustomerinfo{
        width: calc(100% - 50px);
        max-width: 800px;
        margin: 10% auto;
        .content{
            max-height: calc(100vh - 15rem);
            opacity: 1;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
}
.mstcustomer-hide{
    display: none;
    z-index: 5;
    position: absolute;
}

//mst mailinglist popup
.mstmailingpopup-show{
    display: block;
    position:fixed;
    top:0;
    left: 0;
    z-index: 1200;
    width: 100%;
    height: 100%;
    background: rgb(0,0,0,0.5);
    .mstmailingpopup{
        width: calc(100% - 50px);
        max-width: 800px;
        margin: 10% auto;
        .content{
            max-height: calc(100vh - 15rem);
            opacity: 1;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
}
.mstmailingpopup-hide{
    display: none;
    z-index: 5;
    position: absolute;
}

//user newuser popup / edit user
.newuser-show{
    display: block;
    position:fixed;
    top:0;
    left: 0;
    z-index: 1200;
    width: 100%;
    height: 100%;
    background: rgb(0,0,0,0.5);
    .usernewuser{
        position: relative;
        width: calc(100% - 50px);
        max-width: 800px;
        margin: 5% auto;
        .content{
            max-height: calc(100vh - 10rem);
            opacity: 1;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
}
.newuser-hide{
    display: none;
    z-index: 5;
    position: absolute;
}
.topinfo{
    width: 100%;
}

//edit user popup
.edituser-show{
    display: block;
    position:fixed;
    top:0;
    left: 0;
    z-index: 1200;
    width: 100%;
    height: 100%;
    background: rgb(0,0,0,0.5);
    .useredit{
        position: relative;
        width: calc(100% - 50px);
        max-width: 1300px;
        margin: 7% auto;
        .content{
            max-height: calc(100vh - 11rem);
            opacity: 1;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
}
.edituser-hide{
    display: none;
    z-index: 5;
    position: absolute;
}

//my account popup
.myAcc-show{
    display: block;
    position:fixed;
    top:0;
    right: 0;
    z-index: 1200;
    width: 100%;
    height: 100%;
    background: rgb(0,0,0,0.5);
    .myaccount{
        width: 100%;
        min-width: 400px;
        max-width: 800px;
        margin: 10% auto;
        .content{
            max-height: calc(100vh - 15rem);
            opacity: 1;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
}
.myAcc-hide{
    display: none;
    z-index: 5;
    position: absolute;
}


//login
// password resetpopup
.resetpopup-show{
    display: block;
    position:fixed;
    top:0;
    left: 0;
    z-index: 1200;
    width: 100%;
    height: 100%;
    background: rgb(0,0,0,0.5);
    .resetpw{
        width: calc(100% - 50px);
        max-width: 500px;
        margin: 14% auto;
        .content{
            max-height: calc(100vh - 15rem);
            opacity: 1;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
}
.resetpopup-hide{
    display: none;
    z-index: 5;
    position: absolute;
}
// new Account
.newaccpopup-show{
    display: block;
    position:fixed;
    top:0;
    left: 0;
    z-index: 1200;
    width: 100%;
    height: 100%;
    background: rgb(0,0,0,0.5);
    .newAccount{
        width: calc(100% - 50px);
        max-width: 500px;
        margin: 10% auto;
        .content{
            max-height: calc(100vh - 15rem);
            opacity: 1;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
    .newAccountAu{
        width: calc(100% - 50px);
        max-width: 600px;
        margin: 10% auto;
        .content{
            max-height: calc(100vh - 15rem);
            opacity: 1;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
}
.newaccpopup-hide{
    display: none;
    z-index: 5;
    position: absolute;
}

// viewTermPop
.viewTermpopup-show{
    display: block;
    position:fixed;
    top:0;
    left: 0;
    z-index: 1200;
    width: 100%;
    height: 100%;
    background: rgb(0,0,0,0.5);
    .viewTerm{
        width: calc(100% - 50px);
        max-width: 500px;
        margin: 10% auto;
        .content{
            max-height: calc(100vh - 15rem);
            opacity: 1;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
}
.viewTermpopup-hide{
    display: none;
    z-index: 5;
    position: absolute;
}

// my account chg password popup
.chgpass-show{
    display: block;
    position:fixed;
    top:0;
    right: 0;
    z-index: 1200;
    width: 100%;
    height: 100%;
    background: rgb(0,0,0,0.5);
    .chgpass{
        width: 100%;
        min-width: 400px;
        max-width: 800px;
        margin: 10% auto;
        .content{
            max-height: calc(100vh - 15rem);
            opacity: 1;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
}
.chgpass-hide{
    display: none;
    z-index: 5;
    position: absolute;
}

// LoginUserChgPwPopup
.loginuserpwchg-show{
    display: block;
    position:absolute;
    top:0;
    right: 0;
    z-index: 1200;
    width: 100%;
    height: 100%;
    background: rgb(0,0,0,0.5);
    .chgpass{
        width: 100%;
        min-width: 400px;
        max-width: 800px;
        margin: 15% auto;
        .content{
            max-height: calc(100vh - 15rem);
            opacity: 1;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
}
.loginuserpwchg-hide{
    display: none;
    z-index: 5;
    position: absolute;
}

// OrdvdOrderEditPopup
.ordvdorderedit-show{
    display: block;
    position:fixed;
    top:0;
    left: 0;
    z-index: 1200;
    width: 100%;
    height: 100%;
    background: rgb(0,0,0,0.5);
    .ordvdorderedit{
        position: relative;
        width: calc(100% - 50px);
        max-width: 1400px;
        margin: 4% auto;

        .mb-3 {
            margin-bottom: 0.7rem !important;
        }

        .content{
            max-height: calc(100vh - 11rem);
            opacity: 1;
            overflow-x: hidden;
            overflow-y: auto;
        }

        .react-datepicker-wrapper {
            width: 100%;
        }
        
        .react-datepicker-wrapper input {
            width: 100%;
        }

        .productSearchBox {
            background-color: #e2cce2;

            .mb-3 {
                margin-bottom: 0 !important;
            }
        }

        .productBox {
            height: 100%;
            max-height: calc(100% - 63px - 0.7rem);
            overflow-y: auto;

             .wj-flexgrid .wj-cell {
                height: 30px !important;
                padding: 5px 5px !important;
            }

            .wj-cell > div > button {
                height: 21px;
            }
        }
    }
}
.ordvdorderedit-hide{
    display: none;
    z-index: 5;
    position: absolute;
}

.ordvdorderdealergrid {
    width: 1600px;
    height: 850px;
    margin: 100px auto auto;

    .MuiGrid-item {
        background-color: #e0e0e0;
    }

    .goToBtn {
        right: 20px;
    }
}